var $ = require('jquery');

$(function(){
	var $feed = $("#feed");
	
	if (!$feed.length) return;
	
	var $featuredPosts = $feed.find('.featured-posts'),
		$wrap = $featuredPosts.find('.wrap'),
		$posts = $feed.find('.post.featured'),
		$slideCount = $featuredPosts.find('.slide-count');
	
	
	var index = 0,
		postCount = $posts.length;
	
	function slide(distance){
		index += distance;
		
		if (index == postCount)
			index = 0;
		
		if (index < 0)
			index = postCount-1;
		
		$wrap.css({
				transform: 'translateX(-'+(index * 100)+'%)'
			})
		
		$slideCount.children().removeClass('selected').eq(index).addClass('selected');
	}
	
	// Events
	
	$featuredPosts.find('.left-arrow')
		.on('click', function(){
			clearInterval(timerInterval);
			slide(-1);
		})
	
	$featuredPosts.find('.right-arrow')
		.on('click', function(){
			clearInterval(timerInterval);
			slide(1);
		})
	
	
	// Set Up
	
	$posts.each(function(){
		$slideCount.append('<span></span>');
	})
	$slideCount.children().eq(0).addClass('selected');
	
	if (postCount) {
		$featuredPosts.show();
		$posts.clone().appendTo($featuredPosts.find('.wrap'));
	}
	
	
	// Timer
	
	var timerInterval = setInterval(function(){
		slide(1);
	}, 5000)
})